export const servicesApi = [
  {
    title: "Laptops",
    slug: "laptops",
    summary: "We provides high queality low cost latops",
    image: {
      label: "Laptop",
      name:
        "https://imgs.search.brave.com/gUg56OV6b0C00HE3HxYvLCzK2TwM3R3h63Z8LrVDsmo/rs:fit:1126:691:1/g:ce/aHR0cHM6Ly93d3cu/d29ybGRhdGxhcy5j/b20vci93MTIwMC91/cGxvYWQvNzIvMmIv/ZTMvMTAyNHB4LW1v/YmlsZS1zb2Z0d2Fy/ZS1kZXZlbG9wbWVu/dC1sYWJvcmF0b3J5/LWluLXRoZS1lc3Rv/bmlhbi1pbmZvcm1h/dGlvbi10ZWNobm9s/b2d5LWNvbGxlZ2Uu/anBn",
      alt: "ImageOfLaptop",
      rank: 1,
    },
    descriptions: [
      {
        title: "Laptops",
        content: "This is Laptops imported from Europe. It is very good.",
        rank: 1,
        isFeatured: true,
      },
    ],
    metaTitle: "Laptop",
    metaDescription: "Laptop",
    isFeatured: true,
    rank: 1,
  },
  {
    title: "Printers",
    slug: "printers",
    summary: "We provides high queality low cost latops",
    image: {
      label: "Printers",
      name:
        "https://imgs.search.brave.com/7Beei5C0j-EhNE3d1doS-CXFjNlA2xBqV-YTpY3vrqo/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9zMy1h/cC1zb3V0aGVhc3Qt/Mi5hbWF6b25hd3Mu/Y29tL2ltYWdlcy5p/bWFnZXNjaWVuY2Uu/Y29tLmF1L18yMDE5/MDRfaW1hZ2VyLXRy/YW5zZm9ybXMvczNp/bWFnZXMvUHJvZHVj/dHMvRXBzb24tUHJp/bnRlcnMvUDYwMC8x/NjM0L0Vwc29uLVN1/cmVDb2xvci1QNjAw/LUEzLUlua2pldC1Q/cmludGVyLUZyb250/XzhhMzlmZjhhNmI5/YzQzNTViMjk2Zjkz/ZmMyNjA2OGNjLmpw/Zw",
      alt: "ImageOfPrinters",
      rank: 1,
    },
    descriptions: [
      {
        title: "Printer",
        content: "This is Printer imported from Europe. It is very good.",
        rank: 1,
        isFeatured: true,
      },
    ],
    metaTitle: "Printers",
    metaDescription: "Printers",
    isFeatured: true,
    rank: 1,
  },
  {
    title: "IPhone",
    slug: "iphone",
    summary: "We provides high queality low cost latops",
    image: {
      label: "IPhone",
      name:
        "https://imgs.search.brave.com/0fdFa8uUDBjIHgi6XVG42ob6tOeLymaEgM_D7WPbI1g/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9jZWxs/NHBldHMuY29tL3dw/LWNvbnRlbnQvdXBs/b2Fkcy8yMDIwLzEx/LzIwMjAxMTExXzE2/MTI0OF9yZXNpemVk/LmpwZw",
      alt: "ImageOfIPhone",
      rank: 1,
    },
    descriptions: [
      {
        title: "IPhone",
        content: "This is IPhone imported from Europe. It is very good.",
        rank: 1,
        isFeatured: true,
      },
    ],
    metaTitle: "IPhone",
    metaDescription: "IPhone",
    isFeatured: true,
    rank: 1,
  },
];

// export const products = [
//   {
//     image:
//       "https://imgs.search.brave.com/LOS0zlCQhCqzgtM9D4AztNHktnMYhVIGZhMiSNJH9yg/rs:fit:612:408:1/g:ce/aHR0cHM6Ly93d3cu/bWVkYWluYy5vcmcv/d3AtY29udGVudC91/cGxvYWRzLzIwMTgv/MDEvc3VnYXIuanBn",
//     imageAlt: "xzczx",
//     title: "Sugar",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/5chKZl5MedFzMyT_tGEajnbvsnLw5wJ28s6ybMT8ay0/rs:fit:1000:667:1/g:ce/aHR0cHM6Ly9zYXZl/ZGVsZXRlLmNvbS93/cC1jb250ZW50L3Vw/bG9hZHMvMjAxOS8w/NS9FZGlibGUtT2ls/cy5qcGc",
//     imageAlt: "xzczx",
//     title: "Edible oil",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/4fMB04x97HZOOE7cr3hWB3YJS1Vy-SBuYeITitn7YqY/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9kYWls/eWxhYm9yYXRvcmll/cy5jb20vd3AtY29u/dGVudC91cGxvYWRz/LzIwMTcvMDMvc3Rv/Y2t2YXVsdC1ib2ls/ZWQtcmljZTE2NzE5/Mi5qcGc",
//     imageAlt: "xzczx",
//     title: "Rice",
//     type: "Food and Beabreage",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/r1eskPEFbFlaCD0Egik5TSXrpmZeHKtGtH2OVhPh1yM/rs:fit:741:486:1/g:ce/aHR0cHM6Ly9ndXlh/bmFjaHJvbmljbGUu/Y29tL3dwLWNvbnRl/bnQvdXBsb2Fkcy8y/MDIwLzAzL1RpZHkt/VXAtZGV0ZXJnZW50/cy0xLmpwZw",
//     imageAlt: "xzczx",
//     title: "Detergents",
//     type: "Chemical Fertilizers",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/KZHx7ejJIBn2m71wFFkVy0QgKXUbcfDGCFrr4xigefI/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly93d3cu/Z2FubmV0dC1jZG4u/Y29tL3ByZXN0by8y/MDE5LzA4LzIwL1BE/VE4vZmQwMDIzMmEt/MDJkYy00MDVhLWI3/NzctMzk5N2E1ZDM5/ZWRmLUFQMTkyMzI2/MTI3ODQyMTguanBn/P2Nyb3A9ODI1LDQ2/NCx4MTE1LHkyMTkm/d2lkdGg9MzIwMCZo/ZWlnaHQ9MTY4MCZm/aXQ9Ym91bmRz",
//     imageAlt: "xzczx",
//     title: "Vechiles",
//     type: "Machinery and Equipments",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/LOS0zlCQhCqzgtM9D4AztNHktnMYhVIGZhMiSNJH9yg/rs:fit:612:408:1/g:ce/aHR0cHM6Ly93d3cu/bWVkYWluYy5vcmcv/d3AtY29udGVudC91/cGxvYWRzLzIwMTgv/MDEvc3VnYXIuanBn",
//     imageAlt: "xzczx",
//     title: "Sugar",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/5chKZl5MedFzMyT_tGEajnbvsnLw5wJ28s6ybMT8ay0/rs:fit:1000:667:1/g:ce/aHR0cHM6Ly9zYXZl/ZGVsZXRlLmNvbS93/cC1jb250ZW50L3Vw/bG9hZHMvMjAxOS8w/NS9FZGlibGUtT2ls/cy5qcGc",
//     imageAlt: "xzczx",
//     title: "Edible oil",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/4fMB04x97HZOOE7cr3hWB3YJS1Vy-SBuYeITitn7YqY/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9kYWls/eWxhYm9yYXRvcmll/cy5jb20vd3AtY29u/dGVudC91cGxvYWRz/LzIwMTcvMDMvc3Rv/Y2t2YXVsdC1ib2ls/ZWQtcmljZTE2NzE5/Mi5qcGc",
//     imageAlt: "xzczx",
//     title: "Rice",
//     type: "Food and Beabreage",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/r1eskPEFbFlaCD0Egik5TSXrpmZeHKtGtH2OVhPh1yM/rs:fit:741:486:1/g:ce/aHR0cHM6Ly9ndXlh/bmFjaHJvbmljbGUu/Y29tL3dwLWNvbnRl/bnQvdXBsb2Fkcy8y/MDIwLzAzL1RpZHkt/VXAtZGV0ZXJnZW50/cy0xLmpwZw",
//     imageAlt: "xzczx",
//     title: "Detergents",
//     type: "Chemical Fertilizers",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/KZHx7ejJIBn2m71wFFkVy0QgKXUbcfDGCFrr4xigefI/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly93d3cu/Z2FubmV0dC1jZG4u/Y29tL3ByZXN0by8y/MDE5LzA4LzIwL1BE/VE4vZmQwMDIzMmEt/MDJkYy00MDVhLWI3/NzctMzk5N2E1ZDM5/ZWRmLUFQMTkyMzI2/MTI3ODQyMTguanBn/P2Nyb3A9ODI1LDQ2/NCx4MTE1LHkyMTkm/d2lkdGg9MzIwMCZo/ZWlnaHQ9MTY4MCZm/aXQ9Ym91bmRz",
//     imageAlt: "xzczx",
//     title: "Vechiles",
//     type: "Machinery and Equipments",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/LOS0zlCQhCqzgtM9D4AztNHktnMYhVIGZhMiSNJH9yg/rs:fit:612:408:1/g:ce/aHR0cHM6Ly93d3cu/bWVkYWluYy5vcmcv/d3AtY29udGVudC91/cGxvYWRzLzIwMTgv/MDEvc3VnYXIuanBn",
//     imageAlt: "xzczx",
//     title: "Sugar",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/5chKZl5MedFzMyT_tGEajnbvsnLw5wJ28s6ybMT8ay0/rs:fit:1000:667:1/g:ce/aHR0cHM6Ly9zYXZl/ZGVsZXRlLmNvbS93/cC1jb250ZW50L3Vw/bG9hZHMvMjAxOS8w/NS9FZGlibGUtT2ls/cy5qcGc",
//     imageAlt: "xzczx",
//     title: "Edible oil",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/4fMB04x97HZOOE7cr3hWB3YJS1Vy-SBuYeITitn7YqY/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9kYWls/eWxhYm9yYXRvcmll/cy5jb20vd3AtY29u/dGVudC91cGxvYWRz/LzIwMTcvMDMvc3Rv/Y2t2YXVsdC1ib2ls/ZWQtcmljZTE2NzE5/Mi5qcGc",
//     imageAlt: "xzczx",
//     title: "Rice",
//     type: "Food and Beabreage",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/r1eskPEFbFlaCD0Egik5TSXrpmZeHKtGtH2OVhPh1yM/rs:fit:741:486:1/g:ce/aHR0cHM6Ly9ndXlh/bmFjaHJvbmljbGUu/Y29tL3dwLWNvbnRl/bnQvdXBsb2Fkcy8y/MDIwLzAzL1RpZHkt/VXAtZGV0ZXJnZW50/cy0xLmpwZw",
//     imageAlt: "xzczx",
//     title: "Detergents",
//     type: "Chemical Fertilizers",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/KZHx7ejJIBn2m71wFFkVy0QgKXUbcfDGCFrr4xigefI/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly93d3cu/Z2FubmV0dC1jZG4u/Y29tL3ByZXN0by8y/MDE5LzA4LzIwL1BE/VE4vZmQwMDIzMmEt/MDJkYy00MDVhLWI3/NzctMzk5N2E1ZDM5/ZWRmLUFQMTkyMzI2/MTI3ODQyMTguanBn/P2Nyb3A9ODI1LDQ2/NCx4MTE1LHkyMTkm/d2lkdGg9MzIwMCZo/ZWlnaHQ9MTY4MCZm/aXQ9Ym91bmRz",
//     imageAlt: "xzczx",
//     title: "Vechiles",
//     type: "Machinery and Equipments",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/LOS0zlCQhCqzgtM9D4AztNHktnMYhVIGZhMiSNJH9yg/rs:fit:612:408:1/g:ce/aHR0cHM6Ly93d3cu/bWVkYWluYy5vcmcv/d3AtY29udGVudC91/cGxvYWRzLzIwMTgv/MDEvc3VnYXIuanBn",
//     imageAlt: "xzczx",
//     title: "Sugar",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/5chKZl5MedFzMyT_tGEajnbvsnLw5wJ28s6ybMT8ay0/rs:fit:1000:667:1/g:ce/aHR0cHM6Ly9zYXZl/ZGVsZXRlLmNvbS93/cC1jb250ZW50L3Vw/bG9hZHMvMjAxOS8w/NS9FZGlibGUtT2ls/cy5qcGc",
//     imageAlt: "xzczx",
//     title: "Edible oil",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/4fMB04x97HZOOE7cr3hWB3YJS1Vy-SBuYeITitn7YqY/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9kYWls/eWxhYm9yYXRvcmll/cy5jb20vd3AtY29u/dGVudC91cGxvYWRz/LzIwMTcvMDMvc3Rv/Y2t2YXVsdC1ib2ls/ZWQtcmljZTE2NzE5/Mi5qcGc",
//     imageAlt: "xzczx",
//     title: "Rice",
//     type: "Food and Beabreage",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/r1eskPEFbFlaCD0Egik5TSXrpmZeHKtGtH2OVhPh1yM/rs:fit:741:486:1/g:ce/aHR0cHM6Ly9ndXlh/bmFjaHJvbmljbGUu/Y29tL3dwLWNvbnRl/bnQvdXBsb2Fkcy8y/MDIwLzAzL1RpZHkt/VXAtZGV0ZXJnZW50/cy0xLmpwZw",
//     imageAlt: "xzczx",
//     title: "Detergents",
//     type: "Chemical Fertilizers",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/KZHx7ejJIBn2m71wFFkVy0QgKXUbcfDGCFrr4xigefI/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly93d3cu/Z2FubmV0dC1jZG4u/Y29tL3ByZXN0by8y/MDE5LzA4LzIwL1BE/VE4vZmQwMDIzMmEt/MDJkYy00MDVhLWI3/NzctMzk5N2E1ZDM5/ZWRmLUFQMTkyMzI2/MTI3ODQyMTguanBn/P2Nyb3A9ODI1LDQ2/NCx4MTE1LHkyMTkm/d2lkdGg9MzIwMCZo/ZWlnaHQ9MTY4MCZm/aXQ9Ym91bmRz",
//     imageAlt: "xzczx",
//     title: "Vechiles",
//     type: "Machinery and Equipments",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/LOS0zlCQhCqzgtM9D4AztNHktnMYhVIGZhMiSNJH9yg/rs:fit:612:408:1/g:ce/aHR0cHM6Ly93d3cu/bWVkYWluYy5vcmcv/d3AtY29udGVudC91/cGxvYWRzLzIwMTgv/MDEvc3VnYXIuanBn",
//     imageAlt: "xzczx",
//     title: "Sugar",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/5chKZl5MedFzMyT_tGEajnbvsnLw5wJ28s6ybMT8ay0/rs:fit:1000:667:1/g:ce/aHR0cHM6Ly9zYXZl/ZGVsZXRlLmNvbS93/cC1jb250ZW50L3Vw/bG9hZHMvMjAxOS8w/NS9FZGlibGUtT2ls/cy5qcGc",
//     imageAlt: "xzczx",
//     title: "Edible oil",
//     type: "Agro Products",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/4fMB04x97HZOOE7cr3hWB3YJS1Vy-SBuYeITitn7YqY/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly9kYWls/eWxhYm9yYXRvcmll/cy5jb20vd3AtY29u/dGVudC91cGxvYWRz/LzIwMTcvMDMvc3Rv/Y2t2YXVsdC1ib2ls/ZWQtcmljZTE2NzE5/Mi5qcGc",
//     imageAlt: "xzczx",
//     title: "Rice",
//     type: "Food and Beabreage",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/r1eskPEFbFlaCD0Egik5TSXrpmZeHKtGtH2OVhPh1yM/rs:fit:741:486:1/g:ce/aHR0cHM6Ly9ndXlh/bmFjaHJvbmljbGUu/Y29tL3dwLWNvbnRl/bnQvdXBsb2Fkcy8y/MDIwLzAzL1RpZHkt/VXAtZGV0ZXJnZW50/cy0xLmpwZw",
//     imageAlt: "xzczx",
//     title: "Detergents",
//     type: "Chemical Fertilizers",
//   },
//   {
//     image:
//       "https://imgs.search.brave.com/KZHx7ejJIBn2m71wFFkVy0QgKXUbcfDGCFrr4xigefI/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly93d3cu/Z2FubmV0dC1jZG4u/Y29tL3ByZXN0by8y/MDE5LzA4LzIwL1BE/VE4vZmQwMDIzMmEt/MDJkYy00MDVhLWI3/NzctMzk5N2E1ZDM5/ZWRmLUFQMTkyMzI2/MTI3ODQyMTguanBn/P2Nyb3A9ODI1LDQ2/NCx4MTE1LHkyMTkm/d2lkdGg9MzIwMCZo/ZWlnaHQ9MTY4MCZm/aXQ9Ym91bmRz",
//     imageAlt: "xzczx",
//     title: "Vechiles",
//     type: "Machinery and Equipments",
//   },
// ];

export const products = [
  {
    image: "products/bags/IMG_6022-1~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bags/IMG_6025-1~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bags/IMG_6026-1~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bags/IMG_6032~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bags/IMG_6033~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bags/IMG_6034~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bags/IMG_6035-1~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bags/IMG_6040~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bags/IMG_6042~2.JPG",
    imageAlt: "xzczx",
    title: "Hemp Bags",
    type: "Handicraft Hemp Bags",
  },
  {
    image: "products/bag/IMG_6052~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },

  {
    image: "products/bag/IMG_6054~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },

  {
    image: "products/bag/IMG_6055~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },

  {
    image: "products/bag/IMG_6057~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },

  {
    image: "products/bag/IMG_6064~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },

  {
    image: "products/bag/IMG_6069~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },

  {
    image: "products/bag/IMG_6070~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },
  {
    image: "products/bag/IMG_6071~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },
  {
    image: "products/bag/IMG_6072~3.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },
  {
    image: "products/bag/IMG_6076~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },
  {
    image: "products/bag/IMG_6081~2.JPG",
    imageAlt: "xzczx",
    title: "Bags",
    type: "Handicraft Bags",
  },
  {
    image: "products/others/hemp-cap.jpg",
    imageAlt: "xzczx",
    title: "Hemp Cap",
    type: "Hemp Products",
  },

  {
    image: "products/others/hemp-hat.jpg",
    imageAlt: "xzczx",
    title: "Hemp Hat",
    type: "Hemp Products",
  },

  {
    image: "products/others/hemp-shirt.jpg",
    imageAlt: "xzczx",
    title: "Hemp Shirt",
    type: "Hemp Products",
  },

  {
    image: "products/others/hemp-slippers.jpg",
    imageAlt: "xzczx",
    title: "Hemp Slippers",
    type: "Hemp Products",
  },

  {
    image: "products/others/nepali-kagaj-bag.jpg",
    imageAlt: "xzczx",
    title: "Nepali Kagaj Bag",
    type: "Other Products",
  },

  {
    image: "products/others/nepali-khukuri.jpg",
    imageAlt: "xzczx",
    title: "Nepali Khukuri",
    type: "Other Products",
  },
  {
    image: "products/others/nepali-women-carpet.jpg",
    imageAlt: "xzczx",
    title: "Nepali Women Carpet",
    type: "Other Products",
  },
  {
    image: "products/others/nepali-pottery.webp",
    imageAlt: "xzczx",
    title: "Nepali Pottery",
    type: "Other Products",
  },

  {
    image: "products/others/nepali-paper-bags.jpg",
    imageAlt: "xzczx",
    title: "Nepali Paper Bags",
    type: "Other Products",
  },
  {
    image: "products/others/dhaka-topi.jpg",
    imageAlt: "xzczx",
    title: "Dhaka Topi",
    type: "Other Products",
  },
  {
    image: "products/others/pashminashawl.jpg",
    imageAlt: "xzczx",
    title: "Pashmina Shawl",
    type: "Other Products",
  },

  {
    image: "products/others/sclupture.jpg",
    imageAlt: "xzczx",
    title: "Sclupture",
    type: "Other Products",
  },

  // {
  //   image: "products/bag/IMG_6082~2.JPG",
  //   imageAlt: "xzczx",
  //   title: "Bags",
  //   type: "Handicraft",
  // },
  // {
  //   image: "products/bags/IMG_6043~2.JPG",
  //   imageAlt: "xzczx",
  //   title: "Hemp Bags",
  //   type: "Handicraft",
  // },
];

export const team = [
  {
    image: "team/PrakashAryal.jpg",
    fullName: "Mr. Prakash Aryal",
    designation: "Chairman & Managing Director",
  },
  {
    image: "team/PrasannaAryal.jpg",
    fullName: "Mr. Prasanna Aryal",
    designation: "Director",
  },
  {
    image: "team/ShambhuKumarThapaChhetri.jpg",
    fullName: "Mr. Shambhu Kumar Thapa Chettri",
    designation: "Director",
  },

  {
    image: "team/MunaKumariAcharya.jpg",
    fullName: "Ms Muna Kumari Acharya",
    designation: "Manager",
  },

  {
    image: "team/YoogeshKafle.jpeg",
    fullName: "Mr. Yugesh Kafle",
    designation: "Sales and Marketing",
  },
  {
    image: "team/RajendraAcharya.jpg",
    fullName: "Mr. Rajendra Acharya",
    designation: "Sales and Marketing",
  },
  {
    image: "team/KhumBahadurDangi.jpg",
    fullName: "Mr. Khum Bahadur Dangi",
    designation: "Sales and Marketing",
  },
  {
    image: "team/YubarajSubedi.jpg",
    fullName: "Mr. Yubraj Subedi",
    designation: "Sales and Marketing",
  },
  {
    image: "team/TarkRajPoudel.jpg",
    fullName: "Mr. Tark Raj Poudel",
    designation: "Sales and Marketing",
  },

  {
    image: "team/Anita-KC-Head-Of-Marketing.jpeg",
    fullName: "Miss Anita KC",
    designation: "Head Of Marketing",
  },

  {
    image: "team/Anjala-Tamang-Manager.jpeg",
    fullName: "Anjala Tamang",
    designation: "Manager",
  },
];
