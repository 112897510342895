import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
// Import FormInput
import FormInput from "../Common/FormInput";

const SignUpForm = (props) => {
  const [details, setDetails] = useState({
    f_name: "",
    l_name: "",
    email: "",
    username: "",
    password: "",
    c_password: "",
  });
  const handler = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(details);
      // const { data } = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}/auths/signup`,
      //   details
      // );
      // console.log(data);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <section id="signIn_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
              <div className="user_area_wrapper">
                <h2>{props.heading}</h2>
                <div className="user_area_form ">
                  <form onSubmit={handleSubmit} id="form_signIn">
                    <div className="row">
                      <div className="col-lg-12">
                        <FormInput
                          tag={"input"}
                          type={"text"}
                          name={"f_name"}
                          onChange={handler}
                          classes={"form-control"}
                          placeholder={"First Name"}
                        />
                      </div>
                      <div className="col-lg-12">
                        <FormInput
                          tag={"input"}
                          type={"text"}
                          name={"l_name"}
                          onChange={handler}
                          classes={"form-control"}
                          placeholder={"Last Name"}
                        />
                      </div>
                      <div className="col-lg-12">
                        <FormInput
                          tag={"input"}
                          type={"text"}
                          name={"username"}
                          onChange={handler}
                          classes={"form-control"}
                          placeholder={"Username"}
                        />
                      </div>
                      <div className="col-lg-12">
                        <FormInput
                          tag={"input"}
                          type={"text"}
                          name={"email"}
                          onChange={handler}
                          classes={"form-control"}
                          placeholder={"Email Address"}
                        />
                      </div>
                      <div className="col-lg-12">
                        <FormInput
                          tag={"input"}
                          type={"password"}
                          name={"password"}
                          onChange={handler}
                          classes={"form-control"}
                          placeholder={"Password"}
                        />
                      </div>
                      <div className="col-lg-12">
                        <FormInput
                          tag={"input"}
                          type={"password"}
                          name={"c_password"}
                          onChange={handler}
                          classes={"form-control"}
                          placeholder={"Confirm Password"}
                        />
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            I agree with Terms & Conditions
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="submit_button">
                          <FormInput tag={"button"} val={"Sign Up"} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="not_remember_area">
                          <p>
                            Already have an account?{" "}
                            <Link to="/signIn"> Sign Up</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpForm;
