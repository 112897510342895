import React from 'react'
import TeamCards from '../component/OurTeam'
import CommonBanner from '../component/Common/Banner'

import OurPartner from '../component/Common/OurPartner'
import axios from "axios"
import TeamArea from "../component/Home_Two/Team";


const OurTeam = () => {
  
    return ( 
        <>
            <CommonBanner heading="Our Team" page="Our Team"/>
            <TeamArea/>
        </>
    )
}

export default OurTeam;
