import React from "react";
import { Link } from "react-router-dom";
// ServiceCard Area
const ServiceCard = (props) => {
  return (
    <>
      <div className="service-card">
        <img
          src={
            props.img
              ? props.img
              : "https://www.india-briefing.com/news/wp-content/uploads/2019/07/India-Briefing-India%E2%80%99s-Export-and-Import-Trends-2018-19.jpg"
          }
          alt="image_service"
        />
        <div className="service-caption">
          <Link to={`/service_details/${props.slug}`}>
            <h4>{props.heading}</h4>
          </Link>
          <p style={{ textAlign: "center", margin: "10px 0" }}>{props.para}</p>
          <Link to={`/service_details/${props.slug}`} className="read_more_btn">
            <span>
              {props.button}
              <i className="fas fa-long-arrow-alt-right"></i>
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
