import React, { useState, useEffect } from "react";
//  OwlCarousel Slider Import
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//  Client Slider Img Import
import axios from "axios";

const OurPartner = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/partners`,
    })
      .then((res) => setPartners(res.data.data))
      .catch((e) => console.log(e.message));
  }, []);

  let responsive = {
    0: {
      items: 2,
    },
    600: {
      items: 2,
    },
    960: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  };
  return (
    <>
      {partners.length > 0 ? (
        <section id="partner_area_slider" className="bg-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="partner_heading">
                  <h2>Our Bankers:</h2>
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="partner_slider_wrapper">
                  {partners.length > 0 ? (
                    <OwlCarousel
                      className="owl-theme"
                      responsive={responsive}
                      autoplay={true}
                      autoplayHoverPause={true}
                      autoplayTimeout={2500}
                      loop={true}
                      margin={10}
                      nav={false}
                      dots={false}
                    >
                      {partners.map((data, index) => (
                        <a
                          href={data.url ? data.url : "https://nepadristi.com/"}
                          target="blank"
                          key={index}
                        >
                          {" "}
                          <div className="partner_logo" key={index}>
                            <img src={data.image} alt={data.imageAlt} />
                          </div>{" "}
                        </a>
                      ))}
                    </OwlCarousel>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default OurPartner;
