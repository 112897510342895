export const MenuData = [
  {
    name: "Home",
    href: "/",
    has_children: false,
  },
  {
    name: "About",
    href: "/about",
    has_children: true,
    children: [
      {
        name: "Our Team",
        href: "/our_team",
        has_children: false,
      },
    ],
  },

  {
    name: "Products & Services",
    href: "/service",
    has_children: true,
    children: [
      {
        name: "Product Gallery",
        href: "/gallery",
        has_children: false,
      },
      {
        name: "Service",
        href: "/service",
        has_childreen: false,
      },
    ],
  },
  //   {
  //     name: "Services",
  //     href: "/service",
  //     has_children: true,
  //     children: [
  //       // {
  //       //     name:"Export",
  //       //     href:"/service_details",
  //       //     has_children:false
  //       // },
  //       // {
  //       //     name:"Import",
  //       //     href:"/service_details",
  //       //     has_children:false
  //       // },
  //       // {
  //       //     name:"Custom Clearance",
  //       //     href:"/service_details",
  //       //     has_children:false
  //       // },
  //       // {
  //       //     name:"Shipment",
  //       //     href:"/service_details",
  //       //     has_children:false
  //       // }
  //     ],
  //   },

  //   {
  //     name: "Our Products",
  //     href: "/#",
  //     has_children: true,

  //     children: [
  //       {
  //         name: "Kabisa Party 250 ml",
  //         href: "https://www.mutalogroup.com/kabisa#product-2",
  //         has_children: false,
  //         isAbsolute: true,
  //       },
  //     ],
  //   },

  {
    name: "Contact",
    href: "/contact",
    has_children: false,
  },
];
