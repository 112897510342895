import React from "react";
// CopyRight Area
const CopyRight = () => {
  return (
    <>
      <div id="copy_right">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="copy-right-area">
                <p>
                  Copyright @{new Date().getFullYear()}{" "}
                  <span> Aryal Export and Import Pvt.Ltd.</span> By{" "}
                  <span>
                    {" "}
                    <a
                      target="_blank"
                      without="true"
                      rel="noreferrer"
                      href="https://nepadristi.com"
                    >
                      NepaDristi Pvt. Ltd{" "}
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
