import React from "react";
import CommonBanner from "../component/Common/Banner";
import HomeTwoAboutPage from "../component/Home_Two/AboutPage";
import ServiceHomeTwo from "../component/Home_Two/Service";
import OurPartner from "../component/Common/OurPartner";
import TeamArea from "../component/Home_Two/Team";
import Message from "../component/Home_Two/Message";
const About = () => {
  return (
    <>
      <CommonBanner heading="About" page="About" />
      <HomeTwoAboutPage fromAbout={true} />
      {/* <Message /> */}
      {/* <ServiceHomeTwo /> */}
      {/* <TeamArea /> */}
      {/* <OurPartner /> */}
    </>
  );
};

export default About;
