import React, { useEffect, useState } from "react";
// Import SectionHeading
import SectionHeading from "../../Common/SectionHeading";
// Import ServiceCard
import ServiceCard from "../../Common/Service/ServiceCard";
// Import ServiceData
import { ServiceData } from "../../Common/Service/ServiceData";
//  OwlCarousel Slider Import
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import { servicesApi } from "../../../dummyData";

const ServiceHomeTwo = () => {
  let responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    960: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  };

  const [services, setServices] = useState(servicesApi);

  // useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: `${process.env.REACT_APP_BASE_URL}/services`,
  //   })
  //     .then((res) => {
  //       setServices(res.data.data);
  //       console.log("Services data are", res.data.data);
  //     })
  //     .catch((e) => console.log("Service error message: ", e.message));
  // }, []);

  return (
    <>
      <section id="home_two_service">
        {services ? (
          <div className="container">
            <SectionHeading
              heading="Taking care of you and your goods all the way"
              para="We take care of customer needs at national and international level by introducing wide range of new product, technologies and equipments"
            />
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="service_slider_home_two">
                  {services.length > 0 ? (
                    <OwlCarousel
                      className="owl-theme"
                      responsive={responsive}
                      autoplay={true}
                      autoplayHoverPause={true}
                      autoplayTimeout={2500}
                      loop={true}
                      nav={false}
                      dots={true}
                      margin={30}
                    >
                      {services.map((data, index) => (
                        <ServiceCard
                          img={data?.image?.name || ""}
                          heading={data?.title || ""}
                          para={data.summary}
                          button={"Show Detail"}
                          slug={data?.slug || ""}
                          key={index}
                        />
                      ))}
                    </OwlCarousel>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
};

export default ServiceHomeTwo;
