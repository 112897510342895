import { useContext } from "react";
import { Context } from "../../helpers/context";

const ContactInfo = () => {
  const { contact } = useContext(Context);
  return (
    <>
      <div className="contact_info_wrapper">
        <div className="contact_item">
          <h5>Our Address</h5>
          {contact.address.map((e, i) => (
            <p key={i}>{e}</p>
          ))}
        </div>
        <div className="contact_item">
          <h5>Call us on</h5>
          <p>+{contact.contact.mobile}</p>
          <p>+{contact.contact.tel}</p>
        </div>
        {/*
            <div className="contact_item">
                <h5>Mail Us at</h5>
                <p>Mailus@demo.com</p>
                <p>Wesupportyou@demo.com</p>
            </div>
        */}
      </div>
    </>
  );
};

export default ContactInfo;
