import React from "react";
import { Link } from "react-router-dom";
// Import Img
import img1 from "../../../assets/img/common/about-service.jpg";

const HomeTwoAbout = ({ fromAbout }) => {
  return (
    <>
      <section id="service_about">
        <div className="container">
          <div className="row align-items-center ">
            {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about_service_img">
                <img
                  src="https://img.jakpost.net/c/2019/03/11/2019_03_11_67396_1552322655._large.jpg"
                  alt="img-about"
                />
              </div>
            </div> */}
            <div className="col-lg-12  col-md-12 col-sm-12 col-12">
              <div className="about_service_text">
                <div className="heading-left-border">
                  <h2>About Us</h2>
                </div>
                <p
                  style={{ textAlign: "justify" }}
                  className={fromAbout ? null : "cropPara"}
                >
                  We are a company named as Import and Export Pvt Ltd which is a
                  registered private company promoted by some highly motivated,
                  experienced and knowledgeable business minded individuals.
                  This company is duly registered with concerned government
                  authorities like the office of company registrar, Inland
                  Revenue and department of commerce including the office of
                  food technology. Besides of this, this company is a member of
                  Nepal Chamber of Commerce as a bonafide trading company
                  operating its import and export business for the last
                  few years in Nepal.  In recent years the company has become an
                  authorized country distributor of famous brand, beverages and
                  energy drinks (without caffeine). Our company has been
                  ensuring the quality food and beverage products across country
                  in compliance with food quality control of the government. Our
                  company has a long term plan to launch highly innovative
                  technological products to keep the pace of people in the
                  changing world. These innovative products will definitely
                  revolutionize the market in near future.
                </p>
                {fromAbout ? null : (
                  <Link to="/about" className="btn btn-theme">
                    More About Us
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="about_service_text " style={{ paddingTop: "30px" }}>
            <div className="heading-left-border">
              <h2>Vision</h2>
            </div>
            <p
              style={{ textAlign: "justify" }}
              className={fromAbout ? null : "cropPara"}
            >
              Company envisions to create a local market and market outlets for
              most innovative products. Once the new market is created and local
              demand seems to grow, the product manufacturing plant will be
              established in the country. More importantly, it is also in the
              long term plan to start processing of agro and herbal for the
              branding in market. It will ensure a well-established supply chain
              system.
            </p>
            {fromAbout ? null : (
              <Link to="/about" className="btn btn-theme">
                More About Us
              </Link>
            )}
          </div>

          <div className="about_service_text " style={{ paddingTop: "30px" }}>
            <div className="heading-left-border">
              <h2>Products and Services</h2>
            </div>
            <p>
              Our Slogan goes on &lsquo;We serve the nation and the world&rsquo;
              in a broad sense. We aim to promote exports and import in an equal
              way that meets the growing demand of the people in Nepal and the
              world.
            </p>
            <p>
              Export of Nepalese local arts and crafts, agro products, high
              himalayan herbs are key to the national economy which earns hard
              foreign currencies.
            </p>
            <p>
              Importing of plant machineries, chemical fertilizers, high tech
              products, computers and laptop, vehicles, construction machineries
              &amp; equipments, edible oils, petroleum products including many
              more would fulfill the growing demand in the country.
            </p>
            &nbsp; &nbsp;
            <p>
              <h3>Products for Export</h3>
            </p>
            <ul>
              <li>
                <p>Nepalese handicrafts</p>
              </li>
              <li>
                <p>Nepalese ethnic arts &amp; crafts.</p>
              </li>
              <li>
                <p>Nepalese traditional crafts.</p>
              </li>
              <li>
                <p>Agro products</p>
              </li>
              <li>
                <p>Herbs &amp; extracts</p>
              </li>
              <li>
                <p>Cement&nbsp;</p>
              </li>
              <li>
                <p>Tiles</p>
              </li>
            </ul>
            <p>
              <h3>Products for Import</h3>
            </p>
            <ul>
              <li>
                <p>Industrial plant machineries</p>
              </li>
              <li>
                <p>Construction equipment</p>
              </li>
              <li>
                <p>Automobile/ Vehicles</p>
              </li>
              <li>
                <p>Chemical fertilizers</p>
              </li>
              <li>
                <p>Edible oils</p>
              </li>
              <li>
                <p>Computer &amp; Accessories</p>
              </li>
              <li>
                <p>Food beverages &amp; energy drinks</p>
              </li>
              <li>
                <p>Salt/Sugar</p>
              </li>
              <li>
                <p>Pulses, Beans, Cashew, nuts e.t.c</p>
              </li>
            </ul>
              <p>
                In addition our company undertakes bulk supply of many other
                products under a contract manufacturing base in association with
                domestic and overseas manufacturer
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoAbout;
