export const FaqsData = [
    {
        'heading': "TRANSPORT & LOGISTIC SERVICES",
        'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`
    },
    {
        'heading': "SAFE AND FASTER LOGISTIC SERVICE NEER YOU",
        'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`
    },
    {
        'heading': "DIGITAL SHIPPING SOLUTION",
        'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`
    },
    {
        'heading': "WE PROVIDE BEST LOGISTIC SERVICES",
        'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`
    },
    {
        'heading': "MODERN, SAFE AND TRUSTED LOGISTIC COMPANY",
        'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`
    },
    {
        'heading': "OUR CLIENTS AROUND",
        'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`
    }
]