import React from "react";
import ReactHtmlParser from "react-html-parser";
const ServiceContent = ({ content }) => {
  console.log("content is", content);
  return (
    <>
      <div className="col-lg-8">
        <div className="service_details_wrapper">
          <div className="service_details_items">
            <img
              src={
                content.image
                  ? content.image.name
                  : "https://images.unsplash.com/photo-1578575437130-527eed3abbec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZXhwb3J0fGVufDB8fDB8fA%3D%3D&w=1000&q=80"
              }
              alt="img"
            />
            {content.descriptions
              ? content.descriptions.map((description, index) => (
                  <div className="service_details_para" key={index}>
                    <h3>{description.title}</h3>
                    <p>{ReactHtmlParser(description.content)}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceContent;
