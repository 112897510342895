import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../helpers/context";
// ServiceSideBar Area
const ServiceSideBar = ({ services }) => {
  const { contact } = useContext(Context);
  console.log(contact);
  return (
    <>
      <div className="col-lg-4">
        <div className="service_details_sidebar">
          {services ? (
            <div className="sidebar_service_wrappers">
              <h3>Our Service</h3>
              <ul>
                {services.map((service, id) => (
                  <li key={id}>
                    <Link to={`/service_details/${service.slug}`}>
                      {service.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {/* <div className="sidebar_service_wrappers">
                        <h3>Our Brochures</h3>
                        <ul>
                            <li><a href="#!"> <i className="far fa-file-pdf"></i> Service Brochure.Pdf</a></li>
                            <li><a href="#!"><i className="far fa-file-word"></i> About Company.Doc</a></li>
                        </ul>
                    </div> */}
          <div className="sidebar_service_wrappers">
            <h3>Contact Us</h3>
            <div className="contact_sidebar">
              <h6>Visit our office</h6>
              <p>{contact.address[1]}</p>
            </div>
            <div className="contact_sidebar">
              <h6>Call us on</h6>
              <p>Office: {contact.contact.tel}</p>
              <p> Tollfree: {contact.contact.mobile}</p>
            </div>
            <div className="contact_sidebar">
              <h6>Mail Us at</h6>
              <p>Mailus@CargoHub.com</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceSideBar;
