import React from "react";
import { Link } from "react-router-dom";
// Import Img
import img1 from "../../../assets/img/common/about-service.jpg";

const HomeTwoAbout = ({ fromAbout }) => {
  return (
    <>
      <section id="service_about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about_service_img">
                <img
                  src="https://img.jakpost.net/c/2019/03/11/2019_03_11_67396_1552322655._large.jpg"
                  alt="img-about"
                />
              </div>
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <div className="about_service_text">
                <div className="heading-left-border">
                  <h2>About Us</h2>
                </div>
                <p
                  style={{ textAlign: "justify" }}
                  className={fromAbout ? null : "cropPara"}
                >
                  We are a company named as Import and Export Pvt Ltd which is a
                  registered private company promoted by some highly motivated,
                  experienced and knowledgeable business minded individuals.
                  This company is duly registered with concerned government
                  authorities like the office of company registrar, Inland
                  Revenue and department of commerce including the office of
                  food technology. Besides of this, this company is a member of
                  Nepal Chamber of Commerce as a bonafide trading company
                  operating its import and export business for the last
                  few years in Nepal. In recent years the company has become an
                  authorized country distributor of famous brand, beverages and
                  energy drinks (without caffeine). Our company has been
                  ensuring the quality food and beverage products across country
                  in compliance with food quality control of the government. Our
                  company has a long term plan to launch highly innovative
                  technological products to keep the pace of people in the
                  changing world. These innovative products will definitely
                  revolutionize the market in near future.
                </p>
                {fromAbout ? null : (
                  <Link to="/about" className="btn btn-theme">
                    More About Us
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoAbout;
