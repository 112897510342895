import React from "react";
import OurPartner from "../../Common/OurPartner";
import TestimonialSlider from "../../Testimonial";

const HomeTestimonial = () => {
  return (
    <>
      <TestimonialSlider />
      <OurPartner />
    </>
  );
};

export default HomeTestimonial;
