import React, { useEffect, useState } from "react";
import CommonBanner from "../component/Common/Banner";
import ServiceDetailsMain from "../component/ServiceDetails";
import OurPartner from "../component/Common/OurPartner";
import axios from "axios";
import { useParams } from "react-router-dom";
import { servicesApi } from "../dummyData";

const ServiceDetails = () => {
  const { slug } = useParams();
  const [serviceContent, setServiceContent] = useState({});
  const [services, setServices] = useState([]);

  useEffect(() => {
    const servicesData = servicesApi.map((e) => e);
    setServices(servicesData);

    const singleServiceData = servicesApi.filter((e) => e.slug === slug);

    setServiceContent(singleServiceData[0]);
  }, [slug]);

  console.log({ serviceContent, services });

  //   useEffect(() => {
  //     axios({
  //       method: "get",
  //       url: `${process.env.REACT_APP_BASE_URL}/services/${slug}`,
  //     }).then((res) => {
  //       if (res.data.data) {
  //         console.log("Serivce content: ", res.data.data);
  //         setServiceContent(res.data.data);
  //       }
  //     });
  //   }, [slug]);

  //   useEffect(() => {
  //     axios({
  //       method: "get",
  //       url: `${process.env.REACT_APP_BASE_URL}/services`,
  //     }).then((res) => {
  //       console.log("All services: ", res.data.data);
  //       setServices(res.data.data);
  //     });
  //   }, []);

  return (
    <>
      <CommonBanner
        heading={serviceContent ? serviceContent.title : "Service Detail"}
        page={serviceContent ? serviceContent.title : "Service Detail"}
      />
      <ServiceDetailsMain mainContent={serviceContent} services={services} />
      <OurPartner />
    </>
  );
};

export default ServiceDetails;
