import React from "react";
import { Link } from "react-router-dom";
// MenuItems Area
const MenuItems = (props) => {
  return (
    <>
      <li className="nav-item">
        {props.item.isAbsolute ? (
          <a href={props.item.href} className="nav-link" target="_blank">
            {props.item.name}
            {props.item.has_children && <i className="fas fa-angle-down"></i>}
          </a>
        ) : (
          <Link to={props.item.href} className="nav-link">
            {props.item.name}
            {props.item.has_children && (
              <i
                className="fas fa-angle-down"
                onClick={() => {
                  console.log("HELLLO world");
                }}
              ></i>
            )}
          </Link>
        )}
        {props.item.has_children && (
          <ul className="dropdown-menu">
            {props.item.children.map((item, index) => (
              <MenuItems
                item={item}
                onClick={() => {
                  console.log("HELLLO");
                }}
                key={index}
              />
            ))}
          </ul>
        )}
      </li>
    </>
  );
};
export default MenuItems;
