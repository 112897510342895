import React, { useState, useEffect } from "react";
// Import SectionHeading
import SectionHeading from "../../Common/SectionHeading";
// Import TeamCard
import TeamCard from "../../Common/Team/TeamCard";
// Import TeamData
import { TeamData } from "../../Common/Team/TeamData";
//  OwlCarousel Slider Import
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import { team } from "../../../dummyData";

const TeamArea = () => {
  let responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    960: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  };

  const [teams, setTeams] = useState(team);

  // useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: `${process.env.REACT_APP_BASE_URL}/teams`,
  //   })
  //     .then((res) => {
  //       setTeams(res.data.data);
  //     })
  //     .catch((e) => console.log({ error: e.message }));
  // }, []);

  return (
    <>
      <section id="team_area">
        <div className="container">
          <SectionHeading
            heading="Our Team"
            para="Out Team of highly experienced people with full attention to the customer needs. "
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="team-slider">
                {teams.length > 0 ? (
                  <OwlCarousel
                    className="owl-theme"
                    responsive={responsive}
                    autoplay={false}
                    autoplayHoverPause={true}
                    autoplayTimeout={2500}
                    loop={true}
                    margin={10}
                    nav={false}
                    dots={true}
                  >
                    {teams.map((data, index) => (
                      <TeamCard
                        img={data.image}
                        name={data.fullName}
                        des={data.designation}
                        key={index}
                      />
                    ))}
                  </OwlCarousel>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamArea;
