import React, { useState } from 'react'
//Import FormInput
import FormInput from '../Common/FormInput'
// ContactForm Area
import axios from "axios"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
const initialState={
    name:"",
    email:"",
    phone:"",
    subject:"",
    message:"",
}

const alertContent = (title,text,icon) => {
    MySwal.fire({
        title: title,
        text: text,
        icon: icon,
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
    })
}


const ContactForm = () => {
    

    const [formData,setFormData]=useState(initialState);
    
const handleInputChange=(e)=>{
        setFormData({...formData,[e.target.name]:e.target.value})
}

const submitForm=(e)=>{
    e.preventDefault();
    if(formData.name==="" || (formData.email===""|| formData.phone==="") || formData.message===""){
        return
    }
   
    axios({
        method:"post",
        url:`${process.env.REACT_APP_BASE_URL}/contacts`,
        data:formData
    }).then(res=>{
        if(res.data.status==="success"){
            setFormData(initialState);
            return alertContent("Thank You", "Your request has been submitted","success")
        }
        
        else{
            return alertContent("Sorry", "Error. Make sure you have filled all the information.","error");
        }
        }).catch(e=>console.log(e.message))
}   

    return (
        <> 
            <form action="#!">
                <div className="row">
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'name'}
                            classes={'form-control'}
                            placeholder={'Name'}
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name={'email'}
                            classes={'form-control'}
                            placeholder={'Email'}
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'phone'}
                            classes={'form-control'}
                            placeholder={'Phone'}
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'subject'}
                            classes={'form-control'}
                            placeholder={'Subject'}
                            value={formData.subject}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'textarea'}
                            type={'text'}
                            name={'message'}
                            classes={'form-control'}
                            placeholder={'Type Your Messages...'}
                            value={formData.message}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-12">
                        <div className="contact_form_submit">
                            <FormInput
                                tag={'button'}
                                val={'Send'}
                                onClick={submitForm}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ContactForm
