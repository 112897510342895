import { createContext } from "react";

export const Context = createContext();

const GlobalContext = ({ children }) => {
  const contact = {
    address: [
      "Suryabinayak-4, Bhaktapur, Nepal",
      "Lokanthali-01-Madhyapur Thimi, bhaktapur",
    ],
    contact: { mobile: "+977-980-318-8042", tel: "+01-5910775" },
  };
  return <Context.Provider value={{ contact }}>{children}</Context.Provider>;
};

export default GlobalContext;
