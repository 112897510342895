import React, { useEffect, useState } from "react";
// Import Gallery Img
// import img1 from "../../assets/img/gallery/gallery-1.jpg";
// import img2 from "../../assets/img/gallery/gallery-2.jpg";
// import img3 from "../../assets/img/gallery/gallery-3.jpg";
// import img4 from "../../assets/img/gallery/gallery-4.jpg";
// import img5 from "../../assets/img/gallery/gallery-5.jpg";
// import img6 from "../../assets/img/gallery/gallery-6.jpg";

import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import axios from "axios";
import { products } from "../../dummyData";

const GalleryImages = () => {
  const [galleryItems, setGalleryItems] = useState([]);

  // for getting dummy data
  useEffect(() => {
    const newObj = {};
    products.forEach((e) => {
      if (Object.keys(newObj).includes(e.type)) {
        newObj[e.type].push(e);
      } else {
        newObj[e.type] = [e];
      }
    });
    console.log(newObj);
    setGalleryItems(Object.entries(newObj));
  }, []);

  // After adding products and changing model. use this:
  // useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: `${process.env.REACT_APP_BASE_URL}/galleries`,
  //   })
  //     .then(({ data: { data } }) => {
  //       const newObj = {};
  //       data.forEach((e) => {
  //         if (Object.keys(newObj).includes(e.type)) {
  //           newObj[e.type].push(e);
  //         } else {
  //           newObj[e.type] = [e];
  //         }
  //       });
  //       console.log(newObj);
  //       setGalleryItems(Object.entries(newObj));
  //     })
  //     .catch((e) => console.log(e.message));
  // }, []);

  return (
    <section id="gallery_area">
      <div className="container">
        {galleryItems.map((e) => {
          return (
            <>
              <h3 className="font-weight-bold mb-3">{e[0]}</h3>
              <div className="d-flex flex-wrap">
                {e[1].map((product) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 flex gap-2"
                      key={product._id}
                    >
                      <div className="single-gallery"  >
                        <div style={{verticalAlign:"baseline"}}>
                        <ReactFancyBox 
                        defaultThumbnailWidth={100}
                        defaultThumbnailHeight={100}
                        style={{resizeMode:'cover'}}
                          thumbnail={product.image}
                          image={product.image}
                        />
                        </div>
                        <p className="text-center font-weight-bold mt-1">
                          {product.title}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </section>
  );
};

export default GalleryImages;
