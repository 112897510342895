import React from 'react'
import {Link} from 'react-router-dom';
// Import Error img
import img1 from '../../assets/img/common/error.png'

// ErrorArea Area
const ErrorMessages = () => {
    return (
        <>
            <section id="error_area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="error-img">
                                <img src="https://media.istockphoto.com/vectors/page-404-link-to-nonexistent-page-space-astronomy-vector-id1295958579?b=1&k=20&m=1295958579&s=170667a&w=0&h=wFnnllAklUrzTPTLsBwm2Hytl5TTF7gITzH_WjmPQoI=" alt="Error Imgs"/>
                                <h3>Page Not Found</h3>
                                <Link to="/" className="btn btn-theme">Back To Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ErrorMessages
