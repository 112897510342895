import React, { useState, useEffect } from "react";
// Import SectionHeading
import SectionHeading from "../Common/SectionHeading";
// Import ServiceCard
import ServiceCard from "../Common/Service/ServiceCard";
// Import ServiceData
import { ServiceData } from "../Common/Service/ServiceData";
import axios from "axios";
import { servicesApi } from "../../dummyData";

const ServicesCard = () => {
  const [services, setServices] = useState(servicesApi);
  // useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: `${process.env.REACT_APP_BASE_URL}/services`,
  //   })
  //     .then((res) => {
  //       setServices(res.data.data);
  //     })
  //     .catch((e) => console.log(e.message));
  // }, []);

  return (
    <>
      <section id="services_page">
        <div className="container">
          <SectionHeading
            heading="We Serve Various Ways"
            para="Solving your supply chain needs from end to end, taking the
        complexity out of container shipping. We are at the forefront of developing innovation."
          />
          <div className="service_wrapper_top">
            <div className="row">
              {services.map((data, index) => (
                <div className="col-lg-4" key={index}>
                  <ServiceCard
                    img={data.image.name}
                    heading={data.title}
                    para={data.summary}
                    button={"Show Detail"}
                    slug={data.slug}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesCard;
