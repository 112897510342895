import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// Map Area
const Map = () => {
  const mapStyles = {
    height: "50vh",
    width: "100%",
    margin: "0 0 0 0",
  };
  const defaultCenter = {
    lat: 27.673948,
    lng: 85.360454,
  };
  return (
    <>
      <LoadScript googleMapsApiKey="AIzaSyDtygZ5JPTLgwFLA8nU6bb4d_6SSLlTPGw">
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={19}
          center={defaultCenter}
        >
          <Marker position={{ lat: 27.673948, lng: 85.360454 }} />
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default Map;
