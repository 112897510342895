import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Import FormInput
import FormInput from "../../Common/FormInput";
//  OwlCarousel Slider Import
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";

const HomeBannerTwo = () => {
  const [banners, setBanners] = useState([]);

  let responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    960: {
      items: 1,
    },
    1200: {
      items: 1,
    },
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/banners`,
    })
      .then((res) => setBanners(res.data.data))
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <>
      <section id="homeTwo_banner">
        <div className="banner-slider-two">
          {banners.length > 0 ? (
            <OwlCarousel
              className="owl-theme"
              responsive={responsive}
              autoplay={true}
              autoplayHoverPause={true}
              autoplayTimeout={2500}
              loop={true}
              nav={true}
              dots={false}
            >
              {banners
                ? banners.map((banner, id) => (
                    <div
                      className="banner-item-two banner-two-img-one"
                      style={{ backgroundImage: `url(${banner.image})` }}
                      key={id}
                    >
                      <div className="container">
                        <div className="banner_two_inner">
                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <div className="banner-text-two">
                                <h6>Since 2018</h6>
                                <h1>{banner.headline}</h1>
                                <p>{banner.summary ? banner.summary : null}</p>
                                <Link className="btn btn-theme" to="/service">
                                  Our Services
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
              {/* <div className="banner-item-two banner-two-img-one" style={{backgroundImage:'url(https://wallpaperaccess.com/full/4729513.jpg)'}}>
                            <div className="container">
                                <div className="banner_two_inner">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="banner-text-two">
                                            <h6>Since 2018</h6>
                                                <h1>Import and Export Across The Globe.</h1>
                                                <p>Our customers can depend on our broad information and mastery to finish your occupation easily and flawlessly.</p>
                                                <Link className="btn btn-theme" to="/service">Our Services</Link>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div> */}
              {/* <div className="banner-item-two banner-two-img-one" style={{backgroundImage:'url(https://osemployment.com/assets/images/wall2.jpg)'}}>
                            <div className="container">
                                <div className="banner_two_inner">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="banner-text-two">
                                                <h6>Since 2018</h6>
                                                <h1>Import and Export Across The Globe.</h1>
                                                <p>Our customers can depend on our broad information and mastery to finish your occupation easily and flawlessly.</p>
                                                <Link className="btn btn-theme" to="/service">Our Services</Link>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </OwlCarousel>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default HomeBannerTwo;
