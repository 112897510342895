import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import "./assets/css/style.css";
import "./assets/css/meanmenu.min.css";
import "./assets/css/responsive.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome.all.min.css";
import "./assets/css/color.css";
import { CookiesProvider } from "react-cookie";
import GlobalContext from "./helpers/context";

ReactDOM.render(
  <CookiesProvider>
    <GlobalContext>
      <App />
    </GlobalContext>
  </CookiesProvider>,
  document.getElementById("root")
);
