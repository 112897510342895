import React from "react";
// TeamCard Area
const TeamCard = ({ img, name, des }) => {
  return (
    <>
      <div className="team-member">
        <div className="team_inner">
          <img src={img} className="img-responsive" alt="img_team" />
        </div>
        <div className="team_name">
          <h4>{name}</h4>
          <p>{des}</p>
        </div>
      </div>
    </>
  );
};

export default TeamCard;
