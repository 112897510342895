import React from 'react'
import {GoogleMap, LoadScript} from '@react-google-maps/api';
//ContactMap Area
const ContactMap = () => {
    const mapStyles = {
        height: "50vh",
        width: "100%", 
        margin: "0 0 0 0"
    };
    const defaultCenter = {
        lat: 22.8136822, lng: 89.5635596
    }
    return (
        <>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7065.662836366079!2d85.3385594725256!3d27.691604831193985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19921f6d004b%3A0x5a017eef8b2df616!2sShantinagar%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1639377020287!5m2!1sen!2snp" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
            {/* <LoadScript googleMapsApiKey="AIzaSyDtygZ5JPTLgwFLA8nU6bb4d_6SSLlTPGw">
                <GoogleMap mapContainerStyle={mapStyles} zoom={10} center={defaultCenter}></GoogleMap>
            </LoadScript> */}
        </>
    )
}

export default ContactMap
